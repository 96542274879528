// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sm_detail_header {
  margin-top: 24px;
}

.fld_step_002_wrp {
  margin-top: 32px;
}

.fld_col_50 .text_15 {
  font-weight: 500;
  margin-bottom: 18px;
}

.input_phone_number {
  display: flex !important;
  align-items: center;
}
.input_phone_number .mat-mdc-form-field-error {
  position: absolute;
  display: flex;
  top: 48px !important;
  transition-duration: 0.3s;
  font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/app/personal-information/personal-information.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AACA;EACE,gBAAA;AAEF;;AACE;EACE,gBAAA;EACA,mBAAA;AAEJ;;AAEA;EAEE,wBAAA;EACA,mBAAA;AAAF;AACE;EACE,kBAAA;EACA,aAAA;EACA,oBAAA;EACA,yBAAA;EACA,eAAA;AACJ","sourcesContent":[".sm_detail_header {\n  margin-top: 24px;\n}\n.fld_step_002_wrp {\n  margin-top: 32px;\n}\n.fld_col_50 {\n  .text_15 {\n    font-weight: 500;\n    margin-bottom: 18px;\n  }\n}\n\n.input_phone_number {\n  \n  display: flex !important;\n  align-items: center;\n  .mat-mdc-form-field-error{    \n    position: absolute;\n    display: flex;\n    top: 48px !important;\n    transition-duration: 0.3s;\n    font-size: 13px;}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
