import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import {
  trigger,
  state,
  animate,
  transition,
  style,
} from "@angular/animations";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subscription } from "rxjs";
import { connectionInterestService } from "../service/connection.service";
import { RegisterService } from "../service/register.service";

@Component({
  selector: "app-agreement-acceptance",
  templateUrl: "./agreement-acceptance.component.html",
  animations: [
    trigger("height4sAnim", [
      state("true", style({  height: "auto" })),
      state("void", style({  height: 0 })),
      transition("* => *", [animate("0.4s")]),
    ]),
  ],
})
export class AgreementAcceptanceComponent implements OnInit {
  agreementAcceptanceFormDetails: UntypedFormGroup;
  fildShowvalue: boolean[];
  formSubmited: boolean;

  message: any = {};
  subscription: Subscription;
  academicInfoFormValid$: any;

  private agreementAcceptence() {
    this.agreementAcceptanceFormDetails = new UntypedFormGroup({
      agreementTermsAndConditions: new UntypedFormControl("", [Validators.required]),
      agreeAutomatedTearm: new UntypedFormControl("", [Validators.required]),
    });
  }

  editAgreementterms1() {
    this._connectionInterestService.fldShowValueChange([
      true,
      true,
      true,
      true,
    ]);
  }
  editAgreementterms() {
    this._connectionInterestService.formSubmitedData([true]);
  }
  @Input() progressBarData: number;
  constructor(
    public TermsAndConditionsdialog: MatDialog,
    private TermsAndConditionsSnackbar: MatSnackBar,
    private _connectionInterestService: connectionInterestService,
    private registerService: RegisterService
  ) {}

  ngOnInit() {
    // init form controls
    this.agreementAcceptence();
    this._connectionInterestService.FildShowValue.subscribe(
      (fildShowvalue) => (this.fildShowvalue = fildShowvalue)
    );
    this._connectionInterestService.formSubmited.subscribe(
      (formSubmited) => (this.formSubmited = formSubmited)
    );

    // service data
    this.registerService.getData().subscribe((data) => {
      console.log("Service data(Academic) :: ", data);
    });

    // console.log("this.progressBarData", this.progressBarData);
  }

  agreeTermsAndCondition() {
    const TermsAndConditionsdialogRef = this.TermsAndConditionsdialog.open(
      InstitutionalTermsConditionsDialog,
      { disableClose: true }
    );
    TermsAndConditionsdialogRef.afterClosed().subscribe((result) => result);
    setTimeout(
      () =>
        this.agreementAcceptanceFormDetails.controls.agreementTermsAndConditions.setValue(
          "yes"
        ),
      500
    );
  }

  // form sumbit
  agreementAcceptenceSubmit() {
    if (this.agreementAcceptanceFormDetails.valid) {
      this._connectionInterestService.fldShowValueChange([
        false,
        false,
        false,
        false,
      ]);
      this._connectionInterestService.formSubmitedData([true]);
      window.scrollTo({ top: 150, left: 0, behavior: "smooth" });

      // get form data
      this.registerService.pushData(this.agreementAcceptanceFormDetails.value);

      // get service data
      this.registerService.getData().subscribe((data) => {
        console.log("Service data(Final) :: ", data);
      });
    } else {
      this.TermsAndConditionsSnackbar.openFromComponent(
        InstitutionalTermsConditionsNotification,
        {
          duration: 2000,
        }
      );
    }
  }
}

@Component({
  templateUrl: "institutional-terms-conditions-dialog.html",
})
export class InstitutionalTermsConditionsDialog {}

@Component({
  template:
    "<p class='text_13'>Please accept institutional agreement terms and conditions.</p>",
})
export class InstitutionalTermsConditionsNotification {}
