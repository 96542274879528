import { Component, OnInit } from "@angular/core";
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import {
  trigger,
  state,
  animate,
  transition,
  style,
} from "@angular/animations";
import { Subscription } from "rxjs";
import { connectionInterestService } from "./service/connection.service";
// import { ConsoleReporter } from "jasmine";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [
    trigger("height4sAnim", [
      state("true", style({ height: "auto" })),
      state("void", style({ height: 0 })),
      transition("* => *", [animate("0.4s")]),
    ]),
    trigger("height02sAnim", [
      state("true", style({ height: 0 })),
      state("void", style({ height: 0 })),
      transition("* => *", [animate("0.4s")]),
    ]),
    trigger("smHeaderEffect", [
      state("true", style({ top: 0, opacity: 1 })),
      state("void", style({ top: 300, opacity: 0 })),
      transition("* => *", [animate("0.5s")]),
    ]),
  ],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class AppComponent implements OnInit {
  personalDetailThumbnail: boolean = false;
  personalData: any;
  personalSmDetailShow: boolean;
  personalFormShow: boolean = true;
  public personalDetail: any;
  public academicProgramType: any;
  public academicProgramLevel: any;
  fildShowvalue: boolean[];
  formSubmited: boolean;
  personalDetailFormShow: boolean;
  subscription: Subscription;
  progressValue: number = 0;
  progressValue2: number = 0;
  progressValue3: number = 0;
  formProgress: number = 0;
  lastUpdatedValue2: number = 0;
  lastUpdatedValue3: number = 0;

  constructor(private _connectionInterestService: connectionInterestService) {}

  ngOnInit() {
    this._connectionInterestService.FildShowValue.subscribe(
      (messageFildShowValue) => (this.fildShowvalue = messageFildShowValue)
    );
    this._connectionInterestService.formSubmited.subscribe(
      (formSubmitedValid) => (this.formSubmited = formSubmitedValid)
    );
  }
  getProgressBarData(data) {
    this.progressValue = data;
  }
  getProgressBarData2(data) {
    this.progressValue2 = data;
    if (this.lastUpdatedValue2 != this.progressValue2) {
      this.progressValue = this.progressValue + this.progressValue2;
      this.lastUpdatedValue2 = data;
    }
  }
  getProgressBarData3(data) {
    this.progressValue3 = data;
    if (this.lastUpdatedValue3 != this.progressValue3) {
      this.progressValue = this.progressValue + this.progressValue3;
      this.lastUpdatedValue3 = data;
    }
  }
}
